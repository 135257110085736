
.hotScripts-container {
    height: calc(100vh - 80px);
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: #fff;
    margin-bottom: -20px;
    .back-btn {
        padding: 10px;
    }
    .hotScripts-detail-content {
        height: 1%;
        flex: 1;
    }
}
